import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import { beneTxnModalAccount } from "../../../../redux/features/beneficiary/beneTxnAccounts/beneTransactionAccounts";
import dataLoading from "../../../../assets/Beneficiary Icons/inputloder.gif";
import NoAccountFound from "../../../../assets/Beneficiary Icons/noaccfound.png";
import InfiniteScroll from "react-infinite-scroll-component";
import { useRef } from "react";
import { formatAccountNumber } from "../../../_utils/FormatAccountNumber";
import Cancel from "../../../../assets/CreateTransactionIcons/cancelrecent.png";
import Search from "../../../../assets/CreateTransactionIcons/search.png";
import CircleCancel from "../../../../assets/CreateTransactionIcons/cancel.png";
import { checkUsertoken } from "../../../../redux/features/login/login";
import { useLocation } from "react-router-dom";
import { debounce } from "lodash";
import IfscCodeMapper from "../../../_utils/IfscLogoMapper";

const BeneTxnAccountModal = (props) => {
  const dispatch = useDispatch();
  const inputRefAccount = useRef(null);
  const [isActive, setIsActive] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [beneAccount, setBeneAccount] = useState(false);
  const [activeAccBeneData, setActiveAccBeneData] = useState([]);
  const [currentActivePage, setCurrentActivePage] = useState(1);
  const [searchShow, setSearchShow] = useState("");

  const { beneTxnAccountData, beneTxnAccountLoading } = useSelector(
    (state) => state.beneTransactionAccounts
  );

  const haveInactive = activeAccBeneData?.some((val) => {
    return (
      val?.approval_status === "rejected" ||
      val?.approval_status === "blocked" ||
      val?.approval_status === "archived"
    );
  });

  const haveActive = activeAccBeneData?.some((val) => {
    return (
      val?.approval_status === "approved" || val?.approval_status === "pending"
    );
  });

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(
      beneTxnModalAccount({
        page_no: currentActivePage,
        page_size: 50,
        search_string: searchText.length > 0 ? searchText : undefined,
      })
    );
  }, [currentActivePage, searchText, isActive]);

  useEffect(() => {
    if (!beneTxnAccountLoading) {
      setActiveAccBeneData((prevData) =>
        currentActivePage === 1
          ? beneTxnAccountData?.beneficiary_accounts || []
          : [...prevData, ...beneTxnAccountData?.beneficiary_accounts]
      );
    }
  }, [beneTxnAccountData]);

  const archivedMainRef1 = useRef(null);
  const archivedMainRef2 = useRef(null);

  const handleSearchUserChange = (e) => {
    setSearchShow(e.target.value);
    debouncedSetFilterPayload(e.target.value);
  };

  const closeSearchBar = () => {
    setBeneAccount(false);
    setCurrentActivePage(1);
    setSearchText("");
    setSearchShow("");
    inputRefAccount.current.focus();
  };

  useEffect(() => {
    if (beneAccount) {
      inputRefAccount.current.focus();
    }
  }, [beneAccount]);

  const handleClose = () => {
    props.onClose();
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "#30e5e4";
      case "approved":
        return "#4ddd37";
      case "rejected":
      case "blocked":
        return "#ff5860";
      case "archived":
        return "#a3a3a3";
      default:
        return "#4ddd37";
    }
  };

  const handleButtonClick = (dataItem) => {
    props.onAccountSelect(
      dataItem.name.trim(),
      dataItem.bank_acc_no.trim(),
      dataItem.ifsc_code.trim(),
      dataItem.bank_name,
      dataItem.category,
      dataItem.pan_no,
      dataItem.id
    );
  };

  const fetchMoreActiveData = () => {
    setCurrentActivePage((prevPage) => prevPage + 1);
  };

  const scrollToTop1 = () => {
    if (archivedMainRef1.current) {
      archivedMainRef1.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToTop2 = () => {
    if (archivedMainRef2.current) {
      archivedMainRef2.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const debouncedSetFilterPayload = useRef(
    debounce((value) => {
      setSearchText(value);
      setActiveAccBeneData([]);
    }, 500)
  ).current;

  return (
    <>
      <div
        className="fixed top-0 left-0 w-screen h-screen z-[9998] "
        style={{ backgroundColor: "#000000", opacity: "0.8" }}
      ></div>
      <div
        className="flex justify-center items-center fixed top-1/2 left-1/2 z-[9999]"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div
          className="h-fit w-60% flex flex-col bg-[#102C5D] rounded-2xl pb-5"
          style={{ width: "50vw", maxWidth: "600px" }}
        >
          <div className="flex justify-between gap-5">
            {!beneAccount && (
              <h6 className="text-[#DADADA] font-semibold text-lg text-center pr-6 pl-9 pt-5 pb-5">
                Select beneficiary account:
              </h6>
            )}
            <div className="flex items-center mr-4 gap-2 pr-6 pl-9 pt-5 pb-5">
              <>
                {!beneAccount ? (
                  <button
                    className="smallInputIcon"
                    onClick={() => {
                      setBeneAccount(true);
                    }}
                  >
                    <img src={Search} alt="search" />
                  </button>
                ) : (
                  <div className="input_searchDiv">
                    <img
                      src={Search}
                      alt="search"
                      className="search_owner_acc_bar "
                      onClick={() => inputRefAccount.current.focus()}
                    />
                    <input
                      ref={inputRefAccount}
                      type="text"
                      value={searchShow}
                      onChange={handleSearchUserChange}
                      placeholder="Email ID, A/C ID, A/C name, Phone no."
                    />
                    <img
                      src={CircleCancel}
                      alt="search"
                      className="search_owner_acc_bar"
                      onClick={closeSearchBar}
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  </div>
                )}
              </>
              <button className="smallInputIcon" onClick={handleClose}>
                <img src={Cancel} alt="cancel" />
              </button>
            </div>
          </div>
          <div className="associatebutton pr-6 pl-2">
            <Button
              className="associatebuttonPan"
              onClick={() => {
                setActiveAccBeneData([]);
                setCurrentActivePage(1);
                scrollToTop2();
                setIsActive(false);
              }}
              style={{
                backgroundColor: !isActive ? "#ffffff" : "#373F4B",
                color: !isActive ? "#1d1d1d" : "#f9f9f9",
              }}
            >
              Active
            </Button>
            <Button
              className="associatebuttonBank"
              onClick={() => {
                setActiveAccBeneData([]);
                setCurrentActivePage(1);
                scrollToTop1();
                setIsActive(true);
              }}
              style={{
                backgroundColor: isActive ? "#ffffff" : "#373F4B",
                color: isActive ? "#1d1d1d" : "#f9f9f9",
              }}
            >
              Inactive
            </Button>
          </div>
          <div
            id="infinityScrollContainer"
            className="w-full overflow-y-auto h-[545px] pr-7 pl-9 custom-scroll-container relative"
          >
            {isActive ? (
              <div>
                {beneTxnAccountLoading && activeAccBeneData?.length < 50 ? (
                  <div className="absolute inset-0 flex justify-center items-center">
                    <img
                      src={dataLoading}
                      alt="Loading"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </div>
                ) : !haveInactive ? (
                  <>
                    {!beneTxnAccountLoading && (
                      <div className="w-full h-full flex flex-col justify-center items-center gap-5 text-center overflow-hidden">
                        <div className="flex justify-center items-center h-full">
                          <img
                            className="max-w-[80%] pt-[60px]"
                            src={NoAccountFound}
                            alt="No Data"
                          />
                        </div>
                        <div className="text-white text-lg 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl antialiased">
                          <span>No inactive beneficiary accounts found</span>
                        </div>
                      </div>
                    )}
                  </>
                ) : activeAccBeneData && activeAccBeneData.length > 0 ? (
                  <div ref={archivedMainRef2} className="achieved_main">
                    <div className="achieved_scroll">
                      <div className="flex flex-col gap-5 pb-[5px] py-2">
                        <InfiniteScroll
                          style={{ width: "inherit" }}
                          dataLength={activeAccBeneData?.length}
                          next={fetchMoreActiveData}
                          hasMore={
                            currentActivePage <
                            Math.ceil(beneTxnAccountData?.total_count / 50)
                          }
                          loader={
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={dataLoading}
                                alt="Loading"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                }}
                              />
                            </div>
                          }
                          scrollableTarget="infinityScrollContainer"
                          scrollThreshold={0.8}
                        >
                          {activeAccBeneData?.map((val, index) => {
                            if (
                              val?.approval_status === "rejected" ||
                              val?.approval_status === "blocked" ||
                              val?.approval_status === "archived"
                            ) {
                              return (
                                <div
                                  key={index}
                                  className="group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl hover:bg-custom-cardHover bg-custom-card overflow-hidden cursor-none"
                                  style={{
                                    marginBottom: "1rem",
                                    opacity: 0.5,
                                  }}
                                >
                                  <div className="flex flex-row -mr-4">
                                    <div
                                      className="w-4/5 antialiased truncate"
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        textTransform: "capitalize",
                                        color: getStatusColor(
                                          val.approval_status
                                        ),
                                      }}
                                    >
                                      {val.name.toLowerCase()}
                                      <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          Beneficiary
                                        </span>
                                        &nbsp;
                                        <span
                                          style={{
                                            textTransform: "lowercase",
                                          }}
                                        >
                                          name
                                        </span>
                                      </div>
                                    </div>
                                    <div className="w-2/5 text-sm flex flex-row gap-1 justify-end">
                                      <button className="group">
                                        <div className="text-gray-300 visible self-center text-base antialiased group-hover:invisible group-hover:hidden">
                                          {val.ifsc_code}
                                          {val.ifsc_code && (
                                            <div className="text-gray-300 text-xs antialiased mr-[-40px]">
                                              IFSC Code
                                            </div>
                                          )}
                                        </div>
                                      </button>
                                      <div className="flex flex-row justify-start">
                                        <div
                                          className="w-2 4xl:w-2"
                                          style={{
                                            backgroundColor: getStatusColor(
                                              val.approval_status
                                            ),
                                            height: "20px",
                                          }}
                                        ></div>
                                        <div
                                          className={`flex group-hover:px-4 h-[20px] w-0 mt-1 self-center group-hover:visible group-hover:block group-hover:w-full text-xs antialiased font-semibold assignedTxn ${
                                            val.approval_status === "pending"
                                              ? "assignedTxn-pending-box"
                                              : val.approval_status ===
                                                "approved"
                                              ? "assignedTxn-approved-box"
                                              : val.approval_status ===
                                                "rejected"
                                              ? "assignedTxn-rejected-box"
                                              : val.approval_status ===
                                                "blocked"
                                              ? "assignedTxn-blocked-box"
                                              : val.approval_status ===
                                                "archived"
                                              ? "assignedTxn-archived-box"
                                              : ""
                                          }`}
                                          style={{
                                            marginTop: "-20px",
                                            paddingTop: "2px",
                                          }}
                                        >
                                          {val.approval_status === "rejected"
                                            ? "denied"
                                            : val.approval_status}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex flex-row justify-between">
                                    <div className="w-4/5 antialiased text-[#D5D5D5] text-base self-center">
                                      {formatAccountNumber(val.bank_acc_no)}
                                      <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                        Account number
                                      </div>
                                    </div>
                                    <div
                                      className={`flex justify-center group-hover:bg-blue-100 text-center bg-white -mr-4 -mb-4 mt-6`}
                                      style={{
                                        position: "relative",
                                        borderRadius: "20px 0px",
                                      }}
                                    >
                                      <div className="w-[98px] h-[37px] flex items-center justify-center">
                                        <img
                                          src={require("../../../../assets/IfscLogo/" +
                                            IfscCodeMapper(val?.ifsc_code))}
                                          alt="bank"
                                          className="view-all-bank-image"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                            return null;
                          })}
                        </InfiniteScroll>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="absolute inset-0 flex justify-center items-center">
                    <img
                      src={dataLoading}
                      alt="Loading"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div>
                {beneTxnAccountLoading && activeAccBeneData?.length < 50 ? (
                  <div className="absolute inset-0 flex justify-center items-center">
                    <img
                      src={dataLoading}
                      alt="Loading"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </div>
                ) : !haveActive ? (
                  <>
                    {!beneTxnAccountLoading && (
                      <div className="w-full h-full flex flex-col justify-center items-center gap-5 text-center overflow-hidden">
                        <div className="flex justify-center items-center h-full">
                          <img
                            className="max-w-[80%] pt-[60px]"
                            src={NoAccountFound}
                            alt="No Data"
                          />
                        </div>
                        <div className="text-white text-lg 4xl:text-2xl 5xl:text-3xl 6xl:text-4xl antialiased">
                          <span>No active beneficiary accounts found</span>
                        </div>
                      </div>
                    )}
                  </>
                ) : activeAccBeneData && activeAccBeneData.length > 0 ? (
                  <div ref={archivedMainRef1} className="achieved_main">
                    <div className="achieved_scroll">
                      <div className="flex flex-col gap-5 pb-[5px] py-2">
                        <InfiniteScroll
                          style={{ width: "inherit" }}
                          dataLength={activeAccBeneData?.length}
                          next={fetchMoreActiveData}
                          hasMore={
                            currentActivePage <
                            Math.ceil(beneTxnAccountData?.total_count / 50)
                          }
                          loader={
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={dataLoading}
                                alt="Loading"
                                style={{
                                  width: "50px",
                                  height: "50px",
                                }}
                              />
                            </div>
                          }
                          scrollableTarget="infinityScrollContainer"
                          scrollThreshold={0.8}
                        >
                          {activeAccBeneData?.map((val, index) => {
                            if (
                              val?.approval_status === "approved" ||
                              val?.approval_status === "pending"
                            ) {
                              return (
                                <div
                                  key={index}
                                  onClick={() => handleButtonClick(val)}
                                  className="group p-4 flex flex-col group gap-2 drop-shadow-xl rounded-3xl hover:bg-custom-cardHover bg-custom-card overflow-hidden cursor-pointer"
                                  style={{ marginBottom: "1rem" }}
                                >
                                  <div className="flex flex-row -mr-4">
                                    <div
                                      className="w-4/5 antialiased truncate"
                                      style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: " nowrap",
                                        textTransform: "capitalize",
                                        color: getStatusColor(
                                          val.approval_status
                                        ),
                                      }}
                                    >
                                      {val.name.toLowerCase()}
                                      <div className="text-[#D5D5D5] self-center text-xs antialiased">
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          Beneficiary
                                        </span>
                                        &nbsp;
                                        <span
                                          style={{
                                            textTransform: "lowercase",
                                          }}
                                        >
                                          name
                                        </span>
                                      </div>
                                    </div>
                                    <div className="w-2/5 text-sm flex flex-row gap-1 justify-end">
                                      <button className="group">
                                        <div className="text-[#FAFAFA] visible self-center text-base antialiased group-hover:invisible group-hover:hidden">
                                          {val.ifsc_code}
                                          {val.ifsc_code && (
                                            <div className="text-[#D5D5D5] text-xs antialiased mr-[-40px]">
                                              IFSC Code
                                            </div>
                                          )}
                                        </div>
                                      </button>
                                      <div className="flex flex-row justify-start">
                                        <div
                                          className="w-2 4xl:w-2"
                                          style={{
                                            backgroundColor: getStatusColor(
                                              val.approval_status
                                            ),
                                            height: "20px",
                                          }}
                                        ></div>
                                        <div
                                          className={`flex group-hover:px-4 h-[20px] w-0 self-center group-hover:visible group-hover:block group-hover:w-full text-xs antialiased font-semibold assignedTxn ${
                                            val.approval_status === "pending"
                                              ? "assignedTxn-pending-box"
                                              : val.approval_status ===
                                                "approved"
                                              ? "assignedTxn-approved-box"
                                              : val.approval_status ===
                                                "rejected"
                                              ? "assignedTxn-rejected-box"
                                              : val.approval_status ===
                                                "blocked"
                                              ? "assignedTxn-blocked-box"
                                              : val.approval_status ===
                                                "archived"
                                              ? "assignedTxn-archived-box"
                                              : ""
                                          }`}
                                          style={{
                                            marginTop: "-20px",
                                            paddingTop: "1px",
                                          }}
                                        >
                                          {val.approval_status}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex flex-row justify-between">
                                    <div className="w-4/5 antialiased text-[#FAFAFA] text-base self-center">
                                      {formatAccountNumber(val.bank_acc_no)}
                                      <div className="text-[#d5d5d5] self-center text-xs antialiased">
                                        Account number
                                      </div>
                                    </div>
                                    <div
                                      className={`flex justify-center group-hover:bg-blue-100 text-center bg-white -mr-4 -mb-4 mt-6`}
                                      style={{
                                        position: "relative",
                                        borderRadius: "20px 0px",
                                      }}
                                    >
                                      <div className="w-[98px] h-[37px] flex items-center justify-center">
                                        <img
                                          src={require("../../../../assets/IfscLogo/" +
                                            IfscCodeMapper(val?.ifsc_code))}
                                          alt="bank"
                                          className="view-all-bank-image"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                            return null;
                          })}
                        </InfiniteScroll>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="absolute inset-0 flex justify-center items-center">
                    <img
                      src={dataLoading}
                      alt="Loading"
                      style={{ width: "50px", height: "50px" }}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BeneTxnAccountModal;
